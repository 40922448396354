<template>
    
    <div>

        <div v-if="filesStore.state.loading" class="pa-8 text-center drop-zone-image">

            <v-progress-circular
            indeterminate
            :color="app.state.style.primary"
            ></v-progress-circular>

        </div>

        <div v-else class="pa-12 text-center drop-zone-image drop-click"
            v-bind:class="{'dragging-over': dragging}"
            @click="clickUpload()"
            @drop.prevent="onDrop($event)"
            @dragover.prevent
            @dragenter="dragging = true"
            @dragleave="dragging = false">

            <input style="display:none;" type="file" @change="upload" multiple ref="uploadFilesRef">

            {{ $t('Click or drag to upload a file') }}

        </div>

    </div>
    
    
</template>

<style>

.drop-zone-image {
    border: dashed 2px rgb(226, 226, 226);
    border-radius: 20px;
}

.drop-zone-image .dragging-over {
    border-style: solid;
}

.drop-click:hover {
    cursor: pointer;
}

</style>

<script>
    
import { inject, ref } from '@vue/composition-api' ;
import filesStore from '@/store/media/files' ;

export default {
    provide: {
        filesStore
    },
    setup(props, ctx){
    
        const app = inject('app') ;

        const uploadFile = ref(null);
        const uploadFilesRef = ref(null);
        const dragging = ref(false);
        
        const upload = (event) => {

            uploadFiles(event.target.files[0]);
            
        }

        const onDrop = (event) => {

            event.preventDefault();
            console.log('evt', event) ;

            let data = event.dataTransfer ;
            let files = data.files ;

            console.log('files', files) ;
            uploadFiles(files[0]);

        }

        const uploadFiles = (file) => {

            dragging.value = false ;

            filesStore.upload(
                file
            ).then((response) => {

                console.log('Gelukt', response) ;
                app.setSuccess(ctx.root.$t('Image uploaded')) ;
                ctx.emit('uploaded') ;

            }) ;
            
        }

        const clickUpload = () => {

            if (uploadFilesRef.value) {
                uploadFilesRef.value.click();
            }

        }

        return {
            app,
            upload,
            clickUpload,
            uploadFile,
            uploadFilesRef,
            filesStore,
            dragging,
            onDrop
        }
    
    },

};

</script>
