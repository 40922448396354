<template>
    
    <v-dialog
      v-model="dialog"
      width="1000"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn
            v-bind="attrs"
            v-on="on"
            :color="app.state.style.primary"
            outlined
        >
            <span v-if="type == 'files'">{{ $t('Select file') }}</span>
            <span v-else>{{ $t('Select image') }}</span>
        </v-btn>

      </template>

      <v-card>
          
          <v-card-title class="pr-4 pl-4">

            <div>{{ $t('Media') }}</div>

            <v-spacer></v-spacer>

            <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            
        </v-card-title>

        <v-divider></v-divider>

        <div class="pa-4" v-if="type == 'files'">

            <FileUpload @uploaded="fileUploaded()" />

        </div>

        <div class="pa-4" v-else>

            <ImageUpload @uploaded="imageUploaded()" />

        </div>

        <div class="pa-2 pt-0" v-if="type == 'files'">

            <v-card class="ma-2 pa-4 d-flex align-center"
            v-for="file in files"
            :key="file.id">

                <div>{{ file.name }}</div>

                <v-spacer></v-spacer>

                <v-btn icon @click="delFile(file)">
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>

                <v-btn
                    :color="app.state.style.primary"
                    outlined
                    :href="file.public_url"
                    target="_blank"
                >
                    {{ $t('Download') }}
                </v-btn>

                <v-btn
                    :color="app.state.style.primary"
                    dark depressed
                    class="ml-2" @click="select(file)"
                >
                    {{ $t('Select') }}
                </v-btn>
            
            </v-card>

        </div>

        <div class="pa-2 pt-0" v-else>

            <!-- {{ images }} -->

            <v-card v-if="selectedImage">

                <v-card-title class="d-flex">

                    Hey hallow

                    <v-spacer></v-spacer>
                    <v-btn icon @click="selectedImage = null">
                        <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    
                </v-card-title>

                <div class="d-flex">

                    <v-img width="300" src="https://rammelbeek.nl/wp-content/uploads/2020/03/kindvriendelijke-camping-nederland-familiecamping-in-nederland-camping-twente-camping-de-rammelbeek-scaled-e1584608001129.jpg"></v-img>
                    
                    <div class="pa-4">

                        image info

                    </div>
                    
                </div>

            </v-card>

            <div v-else>

                <div class="d-flex flex-wrap justify-space-around">

                    <v-card class="ma-4" width="200"
                    v-for="image in images"
                    :key="image.id">

                        <v-card-title>

                            <v-icon>mdi-image-outline</v-icon>

                            <v-spacer></v-spacer>

                            <v-btn icon @click="del(image)">
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                            
                            <v-btn icon>
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>

                        </v-card-title>

                        <v-img class="select-image" @click="select(image)" height="200" 
                        :src="image.thumb.public_url"></v-img>
                    
                    </v-card>

                </div>

                <div class="pa-2" v-if="startAfter">

                    <v-btn
                        v-intersect="onIntersect"
                        :color="app.state.style.primary"
                        outlined block
                        @click="getImages()"
                        :loading="imageLoadMoreLoading"
                    >
                        {{ $t('Load more') }}
                    </v-btn>
                    
                </div>

            </div>

        </div>
        
      </v-card>

    </v-dialog>

</template>

<style>
.select-image {
    cursor: pointer;    
}
</style>

<script>
    
import { inject, ref, watch } from '@vue/composition-api' ;
import ImageUpload from '@/components/media/ImageUpload';
import FileUpload from '@/components/media/FileUpload';

import imagesStore from '@/store/media/images';
import filesStore from '@/store/media/files' ;

export default {
    props: ['component', 'type'],
    components: {
        ImageUpload,
        FileUpload
    },
    provide: {
        imagesStore
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const dialog = ref(false) ;
        const startAfter = ref(null) ;
        const selectedImage = ref(null) ;
        const images = ref([]) ;
        const files = ref([]) ;
        const limit = ref(24) ;

        const imageLoadMoreLoading = ref(false);

        const getImages = () => {

            imageLoadMoreLoading.value = true;

            imagesStore.get(startAfter.value, limit.value)
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {

                    let img = doc.data() ;
                    img.id = doc.id ;

                    images.value.push(img) ;

                    if(querySnapshot.size == limit.value){
                        startAfter.value = doc.data().created ;
                    }else{
                        startAfter.value = null ;
                    }

                }) ;

                console.log("querySnapshot.size ", startAfter.value, querySnapshot.size, limit.value) ;

            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
                app.setError(error) ;
            });

            imageLoadMoreLoading.value = false;
            
        };

        const getFiles = () => {

            filesStore.get(startAfter.value, limit.value)
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {

                    let img = doc.data() ;
                    img.id = doc.id ;

                    files.value.push(img) ;

                    if(querySnapshot.size == limit.value){
                        startAfter.value = doc.data().created ;
                    }else{
                        startAfter.value = null ;
                    }

                }) ;

                console.log("querySnapshot.size ", startAfter.value, querySnapshot.size, limit.value) ;

            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
                app.setError(error) ;
            });
            
        };

        const select = (imageData) => {

            // console.log("selected", imageData) ;
            ctx.emit('selected', imageData) ;
            dialog.value = false ;
            
        };

        const imageUploaded = () => {

            images.value = [] ;
            startAfter.value = null ;
            getImages() ;
            
        };

        const fileUploaded = () => {

            files.value = [] ;
            startAfter.value = null ;
            getFiles() ;
            
        };

        const del = (image) => {

            if(confirm(ctx.root.$t('Are you sure?'))){

                imagesStore.del(image.id).then(() => {

                    images.value = [] ;
                    startAfter.value = null ;
                    getImages() ;

                }) ;
                
            }
            
        };

        const delFile = (file) => {

            if(confirm(ctx.root.$t('Are you sure?'))){

                filesStore.del(file.id).then(() => {

                    files.value = [] ;
                    startAfter.value = null ;
                    getFiles() ;

                }) ;
                
            }
            
        };

        

        watch(() => dialog.value, () => {

            if(props.type == 'files'){
                files.value = [] ;
                startAfter.value = null ;
                getFiles() ;
            }else{
                images.value = [] ;
                startAfter.value = null ;
                getImages() ;
            }

        });

        const onIntersect = (entries, observer, isIntersecting) => {
            
            if (isIntersecting)
                getImages();

        }
 
        return {
            app,
            dialog,
            selectedImage,
            getImages,
            images,
            select,
            imageUploaded,
            del,
            startAfter,
            getFiles,
            fileUploaded,
            files,
            delFile,
            onIntersect,
            imageLoadMoreLoading
        }
    
    },

};

</script>