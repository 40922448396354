import { reactive } from '@vue/composition-api' ;
// import { i18n } from "@/plugins/i18n";

import firebase from '@/plugins/firebase'
import app from "@/store/app" ;
import api from "@/store/api" ;
import currentWebsite from "@/store/websites/currentWebsite" ;

const state = reactive({
    dialog: false,
    loading: false,
});


const upload = (file) => {

    state.loading = true ;

    let formData = new FormData();

    formData.append("files", file) ;
    formData.append('type', 'website') ;
    formData.append('type_id', currentWebsite.id()) ;

    return api.post("/media/images", formData).then((response) => {

        let img = response.data ;

        img.created = new Date() ;

        return firebase.firestore()
        .collection("websites")
        .doc(String(currentWebsite.id()))
        .collection("images")
        .add(img)
        .then(() => {

            state.loading = false ;

            return true ;

        }).catch(error => {

            state.loading = false ;
            console.log(error) ;
            app.setError(error);
            return error ;
            
        });

    })
    .catch(error => {

        state.loading = false ;
        console.log(error) ;
        app.setError(error);
        return error ;
        
    });
        

}

const get = (startAfter = null, limit = 32) => {

    console.log('startAfter', startAfter) ;

    let query = firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("images")
    .orderBy("created", 'desc') ;

    if(startAfter != null){
        query = query.startAfter(startAfter) ;
    }

    query = query.limit(limit).get() ;

    return query ;

}

const del = (id) => {

    let query = firebase.firestore()
    .collection("websites")
    .doc(String(currentWebsite.id()))
    .collection("images")
    .doc(String(id))
    .delete() ;

    return query ;

}

export default { 
    state, 
    upload,
    get,
    del
 } ;